import { useCallback, useEffect, useMemo, useState } from 'react';
import Table from '../../../components/table';
import PageLoader from '../../../components/loader';
import { afiiliateMarketingService } from '../../../lib/api/affiliate';
import CompanyNameList from '../modal/companyNameList';

const AffiliatedUsers = () => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [companyList, setCompanyList] = useState<string[]>([]);
    const [copiedId, setCopiedId] = useState<string | null>(null);


    const copyToClipboard = (url: string) => {
        navigator.clipboard
            .writeText(url)
            .then(() => {
                setCopiedId(url);  
                setTimeout(() => setCopiedId(null), 2000); 

            })
            .catch((err) => {
                setCopiedId(null);
            });
    };

    const columns = useMemo(
        () => [
            {
                dataIndex: 'firstName',
                title: 'First Name',
                render: (item: any) => (
                    <>
                        <div className="d-flex">
                            <div className="text-crop" style={{ top: '6px' }}>
                                <div className="big-text text-capitalize" title={item.referralCode}>
                                    {item.firstName}
                                </div>
                            </div>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'lastName',
                title: 'Last Name',
                render: (item: any) => (
                    <>
                        <div className="d-flex">
                            <div className="text-crop" style={{ top: '6px' }}>
                                <div className="big-text text-capitalize" title={item.lastName}>
                                    {item.lastName}
                                </div>
                            </div>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'email',
                title: 'Email',
                render: (item: any) => (
                    <>
                        <div className="d-flex">
                            <div className="text-crop" style={{ top: '6px' }}>
                                <div className="big-text text-capitalize" title={item.email}>
                                    {item.email}
                                </div>
                            </div>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'phoneNumber',
                title: 'Contact Number',
                render: (item: any) => (
                    <div className="d-flex">
                        <div className="text-crop" style={{ top: '6px' }}>
                            <div className="big-text text-capitalize" title={item.referralCode}>
                                {item?.phoneNumber ? <>{item?.phoneNumber}</> : 'NA'}
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                dataIndex: 'referralCode',
                title: 'Referral Code',
                render: (item: any) => (
                    <>
                        <div className="d-flex">
                            <div className="text-crop" style={{ top: '6px' }}>
                                <div className="big-text text-capitalize" title={item.referralCode}>
                                    {item.referralCode}
                                </div>
                            </div>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'referralURL',
                title: 'Referral URL',
                render: (item: any) => (
                    <>
                        <div className="d-flex">
                            <div className="text-crop" style={{ top: '6px' }}>
                                <div className="big-text" title={item.referralURL}>
                                    {item.referralURL.substring(0, 22)} &nbsp;&nbsp;
                                    <i
                                        title={copiedId === item.referralURL ? 'Copied!' : 'Copy'}
                                        className={`${
                                            copiedId === item.referralURL ? 'fs-20 bi bi-check2' : 'bi bi-copy'
                                        } cursor-pointer`}
                                        onClick={() => {
                                            copyToClipboard(item.referralURL);
                                        }}
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'companiesRegistered',
                title: 'Companies Registered',
                render: (item: any) => (
                    <div className="d-flex">
                        <div className="text-crop" style={{ top: '6px' }}>
                            <div className="big-text text-capitalize" title={item.companiesRegistered}>
                                {item?.companiesRegistered >= 0 ? (
                                    <span
                                        onClick={() => {
                                            setShow(true);
                                            setCompanyList(item.companyNameList);
                                        }}
                                        className="cursor-pointer"
                                    >
                                        {item?.companiesRegistered}
                                    </span>
                                ) : (
                                    'NA'
                                )}
                            </div>
                        </div>
                    </div>
                ),
            },
        ],
        [copiedId]
    );

    const getList = useCallback(async () => {
        try {
            sessionStorage.clear();
            !loading && setLoading(true);

            const { resultObject } = await afiiliateMarketingService.getAllAfilliateMarketersList();

            setData(resultObject);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [loading]);

    useEffect(() => {
        (async function () {
            await getList();
        })();
    }, []);

    return (
        <>
            <div className="company-page-contener employer-table">
                {loading ? (
                    <PageLoader />
                ) : (
                    <>
                        <h1 className="mb-5 mt-4">Affiliate Users</h1>

                        <Table columns={columns} data={data} />
                    </>
                )}

                {show && <CompanyNameList show={show} setShow={setShow} companyList={companyList} />}
            </div>
        </>
    );
};

export default AffiliatedUsers;
